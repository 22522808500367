import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSave, useSet, useQuery, useDetail } from "seed/gql";
import View from "components/form/Form.view";
import { usePost, useGetCall, useGet } from "seed/api";
import { Loading } from "seed/helpers";
import { useLocation } from "react-router";



function Form(props) {

  const formId = props.match.params.form_id;
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  
  const token = query.get("token");
  const [responseId, setResponseId] = useState(query.get("response_id"));
  const [responseData, setResponseData] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [multipleData, setMultipleData] = useState({});
  const [isShareShown, setIsShareShown] = useState(false);
  const [isResponsesShown, setIsResponsesShown] = useState(false);
  const [responses, setResponses] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isInvitation, setIsInvitation] = useState(query.get("response_id") != null);
  const [submitType, setSubmitType] = useState("DRAFT");

  const isEdit = query.get("response_id") != null && isAdmin;
  const isOwner = !responseData?.user_id || responseData?.user_id == sessionStorage.getItem("id");

  const userType = function(){
    if(isOwner) return "OWNER"
    if(isAdmin) return "ADMIN"
    return "GUEST"
  }();

  function isPositiveInteger(n) {
    return n >>> 0 === parseFloat(n);
  }

  function initMultipleData(o, parent = "", path = "") {
    for (var i in o) {
      if (isPositiveInteger(i)) {
        multipleData[(path != "" ? path + "." + parent : parent)] = o.length
        setMultipleData(multipleData)
      }
      if (o[i] !== null && typeof (o[i]) == "object") {
        if (!isPositiveInteger(parent)) {
          initMultipleData(o[i], i, parent != "" ? (path != "" ? (path + "." + parent) : parent) : "");
        } else {
          let numParent = "[" + parent + "]"
          initMultipleData(o[i], i, parent != "" ? (path != "" ? (path + numParent) : numParent) : "");
        }
      }
    }
  }

  function preProcessMultipleData(o, parent = "", path = "", parentObj) {
    let bk = false
    for (var i in o) {
      if (isPositiveInteger(i)) {
        let currentLength = o.length
        let storedLength = multipleData[(path != "" ? path + "." + parent : parent)] ?? 1
        console.log(currentLength + " " + storedLength)
        if (currentLength > storedLength) {
          parentObj[parent] = parentObj[parent].slice(0, storedLength - currentLength)
          bk = true
        }
      }
      if (o[i] !== null && typeof (o[i]) == "object") {
        if (!isPositiveInteger(parent)) {
          preProcessMultipleData(o[i], i, parent != "" ? (path != "" ? (path + "." + parent) : parent) : "", o);
        } else {
          let numParent = "[" + parent + "]"
          preProcessMultipleData(o[i], i, parent != "" ? (path != "" ? (path + numParent) : numParent) : "", o);
        }
      }
      if (bk) break;
    }
  }

  if (formId != null)
    localStorage.setItem("formId", formId);

  const qForm = useDetail(`
    {
      form {
        name
        settings
        fields {
          name
          type
          isMultiple
          settings
          subFields {
            name
            type
            isMultiple
            settings
            subFields {
              name
              type
              isMultiple
              settings
            }
          }
        }
      }
    }`, formId)

  const [callLastResponse, qLastResponse] = useGetCall("/responses/last_response", isEdit ? { response_id: responseId, token } : null, {
    onCompleted: (data) => {
      if(data && data.length > 1 && !responses.length) {
        setResponses(data);
        setIsResponsesShown(true);
      }
      else if (data && data.length == 1) {
        const lastResponse = data[0]
        if (isEdit || 
          lastResponse.type == "DRAFT" ||
          (lastResponse.type == "FINAL" && window.confirm("Deseas utilizar la información del último formulario enviado como base?"))
        ) {
          setResponseId(lastResponse.id)
          setResponseData(lastResponse)
          setInitialValues(lastResponse.values ?? {})
          initMultipleData(lastResponse.values ?? {})
        }
      }
    }
  })

  const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: data => {
      
      const isAdmin = data.username == "erick.rivas@chevez.com.mx" || data.username == "admin@email.com" ||
        data.username == "lreyes@chevez.com.mx" || data.username == "rosuna@chevez.com.mx" || 
        data.username == "jexigai@chevez.com.mx" || data.username == "smazariegos@chevez.com.mx"
      setIsAdmin(isAdmin)

      if(isEdit && !isAdmin) return props.history.replace("/logout")
      callLastResponse()
      
    },
    onError: () => {
      props.history.replace("/logout");
    }
  })  

  const [cSaveResponse, qSaveResponse] = usePost("/responses/save_form", {
    onCompleted: (data) => props.history.replace("/submitted_form/" + submitType + "/" + formId)
  });

  const [cEditResponse, qEditResponse] = usePost("/responses/edit_form", {
    onCompleted: (data) => {
      if(isAdmin && data.type == "EDIT") window.location.replace("/admin")
      else props.history.replace("/submitted_form/" + submitType + "/" + formId)
    }
  });

  useEffect(() => callAuth(), [isAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  if (qForm.loading || qLastResponse.loading || qSaveResponse.loading || qEditResponse.loading)
    return <main id="content">
      <div
        class="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{
          height: "32rem",
          marginRight: "20px",
          backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)"
        }}
      ></div>
      <div class="container py-10 py-sm-7" style={{ height: "30rem" }}>
        <Loading color="#fff" class="mt-10" />
      </div>
    </main>

  if (qForm.error) return ""

  
  const onSelectResponse = (response) => {

    setIsResponsesShown(false);
    setIsInvitation(response.invitation != null);

    if(response?.type == "FINAL" && window.confirm("Deseas utilizar la información del último formulario enviado como base?")) {
      setResponseData(response)
      setInitialValues(response.values)
      initMultipleData(response.values)
    }
    else {
      setResponseData(response)
      setResponseId(response.id)
      setInitialValues(response.values ?? {})
      initMultipleData(response.values ?? {})
    }

  }

  const onSubmit = values => {

    preProcessMultipleData(values)

    if (submitType == "FINAL") {
      if (window.confirm("¿Estás seguro de enviar la información final?"))
        return cSaveResponse({ values: values, form_id: formId, type: submitType, response_id: responseId });
    } 
    else {
      if(responseId) return cEditResponse({ values: values, response_id: responseId, type: submitType } )
      return cSaveResponse({ values: values, form_id: formId, type: submitType });
    }

  }

  let formData = { ...qForm.data.form }
  formData.settings = (qForm.data.form.settings)

  // Recursively parse settings
  const parseSettings = (fields) =>
    fields.map(field => {

      const newField = Object.assign({}, field);
      if (newField?.subFields?.length > 0) {
        newField.subFields = parseSettings(newField.subFields)
        newField.subFields.sort((f1, f2) => f1.id - f2.id)
      }
      return newField;

    });

  let fieldsData = parseSettings(qForm.data.form.fields);
  fieldsData.sort((f1, f2) => f1.id - f2.id)

  const error = qSaveResponse.error ? "Ha ocurrido un error al cargar tu información, prueba más tarde" : null;

  return <View
    formId={formId}
    responseId={responseId}
    responseData={responseData}
    formData={formData}
    initialValues={initialValues}
    responses={responses}
    isEdit={isEdit}
    isOwner={isOwner}
    isInvitation={isInvitation}
    userType={userType}
    fieldsData={fieldsData}
    multipleData={multipleData}
    isShareShown={isShareShown}
    isResponsesShown={isResponsesShown}
    isAdmin={isAdmin}
    setMultipleData={setMultipleData}
    setIsShareShown={setIsShareShown}
    setResponseId={setResponseId}
    setResponseData={setResponseData}
    error={error}
    setSubmitType={setSubmitType}
    onSubmit={onSubmit}
    onSelectResponse={onSelectResponse}
  />;

}

Form.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  history: PropTypes.object
};

export default Form;