import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { usePost, useGetCall } from "seed/api";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";


function Admin(props) {

  const formId = 1;
  const [isAuth, setIsAuth] = useState(false)
  const [exported, setExported] = useState(false);
  const [mainResponse, setMainResponse] = useState(null);
  const [complementaryResponses, setComplementaryResponses] = useState([]);
  const [maxVersions, setMaxVersions] = useState({});

  const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: data => {
      const isAdmin = data.username == "erick.rivas@chevez.com.mx" ||  data.username == "admin@email.com" ||
        data.username == "lreyes@chevez.com.mx" || data.username == "rosuna@chevez.com.mx" || 
        data.username == "jexigai@chevez.com.mx" || data.username == "smazariegos@chevez.com.mx"
      if (!isAdmin) return props.history.replace("/logout")
      setIsAuth(true);
    },
    onError: () => props.history.replace("/login")
  })

  const [callExport, reqExport] = usePost("/responses/export_excel", {
    onCompleted: (data) => {
      setExported(true);
      var link=document.createElement('a');
      link.href = data.response;
      link.download = "work_highlights.xlsx";
      link.click();
  },
    onError: () => {
      window.alert("Error al exportar la información, prueba de nuevo");
      window.location.reload()
    }
  });

  const qResponses = useQuery(`{
    responses {
      values
      type
      createdAt
      version
      token
      type
      form {
        name
        settings
      }
      user {
        firstName
        lastName
      }
    }
  }`, `(form.id=${formId})`, { orderBy: "-created_at" });


  useEffect(() => {
    callAuth();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { responses = [] } = qResponses.data;
    const versions = {};
    responses.forEach(response => {
      const token = response.token;
      if(!versions[token]) versions[token] = 0;
      versions[token] = Math.max(versions[token], response.version);
    });
    setMaxVersions(versions);
  }, [qResponses.data]);

  if (!isAuth) return <div className="pt-10"><Loading /></div>;
  if (qResponses.loading) return <div></div>
  const { responses = [] } = qResponses.data;

  const onExport = () => {
    callExport({
      main_response_id: mainResponse.id,
      complementary_responses_ids: complementaryResponses.map(res => res.id),
    });
  }

  const onSelect = (response) => {
    if (mainResponse == null) setMainResponse(response);
    else setComplementaryResponses([...complementaryResponses, response]);
  }

  const onEdit = (response) => {
    window.location.href = "/form/1?response_id=" + response.id
  }

  const onDeselectMain = () =>
    setMainResponse(null);

  const onDeselectComplementary = (index) =>
    setComplementaryResponses(complementaryResponses.filter((_, i) => i !== index));

  return <main id="content" role="main">
    <div
      class="position-fixed top-0 right-0 left-0 bg-img-hero"
      style={{
        height: "32rem",
        backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
      }}
    >
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
        >
          <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
        </svg>
      </figure>
    </div>

    <div class="container py-5 py-sm-7">

      <a class="d-flex justify-content-center mb-5" href="#">
        <img
          class="z-index-2"
          src="/resources/images/logo.png"
          alt="Logo"
          style={{ width: "8rem" }}
        />
      </a>

      <div class="row justify-content-center">
        <div class="col-8">
          <div class="card card-lg pb-3">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-11 mt-3 text-center">

                  <div className="d-flex align-items-start flex-column w-100">
                  <div class="d-flex align-items-center justify-content-between w-100 text-center py-2 px-3 mb-5 font-weight-bold text-uppercase" style={{ backgroundColor: "rgb(234, 247, 243)" }}>
                      <label style={{ fontSize: "0.95rem", cursor: "pointer" }}>Listado de respuesta</label>
                      <a className="btn btn-sm btn-outline-primary bg-white mb-0" style={{color: "#2080e5"}} 
                      href="https://app.workix.mx/p/crzrk">Generador de formatos</a>
                    </div>

                    <table className="table">
                      <thead>
                        <tr>
                          <td>ID</td>
                          <th style={{ width: "35%" }}>Usuario</th>
                          <th style={{ width: "15%" }}>Fecha</th>
                          <th style={{ width: "5%" }}>Versión</th>
                          <th className="float-right">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          responses.map((response, index) =>
                            <tr key={index}>
                              <td className="pt-3">{response.id}</td>
                              <td className="pt-3">
                                <div>
                                  {response.user.firstName} {response.user.lastName} - 
                                  {response?.values?.practice_area ? ` (${response.values.practice_area})` : ""}
                                </div>
                                {
                                  response.type == "DRAFT" &&
                                    <span className="badge badge-pill badge-warning">Borrador</span>
                                }
                              </td>
                              <td className="pt-3">{String(new Date(response.createdAt).getDate()).padStart(2, '0') + "." +
                                String(new Date(response.createdAt).getMonth()).padStart(2, '0') + "." +
                                new Date(response.createdAt).getFullYear()}
                              </td>
                              <td className="pt-3">
                                {response.version}
                              </td>
                              <td className="float-right">
                                <button
                                  className={`btn btn-sm mr-1 ${ 
                                    maxVersions[response.token] > response.version ? "btn-outline-secondary" : "btn-outline-primary"}`}
                                  disabled={maxVersions[response.token] > response.version}
                                  onClick={() => onEdit(response)}
                                >
                                  <i 
                                    class={`fas fa-pen mr-1 ${ maxVersions[response.token] > response.version ? "text-secondary" : "text-primary"}`}
                                  /> Editar
                                </button>
                                {
                                  response.id != mainResponse?.id && !complementaryResponses.find(r => r.id === response.id) ?
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => onSelect(response)}
                                    >
                                      <i class="fas fa-file-export mr-1 text-white"></i> Exportar
                                    </button> : null
                                }
                              </td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>

                  {
                    mainResponse != null ?
                      <div className="px-4 py-4 mt-7" style={{ background: "rgb(234, 247, 243)", border: "1px solid #eee" }}>
                        <div className="d-flex align-items-start flex-column w-100 mb-4">
                          <label className="font-weight-bold text-uppercase">Exportación</label>
                        </div>
                        <div className="d-flex align-items-start flex-column w-100">
                          <i className="mb-1">Exportación principal</i>
                          {
                            mainResponse != null
                              ? <div className="d-flex flex-column w-100 bg-white">
                                <div className="row my-1 py-1 px-2">
                                  <div className="col-1 d-flex align-items-center justify-content-center">{mainResponse.id}</div>
                                  <div className="col-5 d-flex align-items-center">{mainResponse.user.firstName} {mainResponse.user.lastName}</div>
                                  <div className="col-2 d-flex align-items-center">
                                    {String(new Date(mainResponse.createdAt).getDay()).padStart(2, '0') + "." +
                                      String(new Date(mainResponse.createdAt).getMonth()).padStart(2, '0') + "." +
                                      new Date(mainResponse.createdAt).getFullYear()}</div>

                                  <div className="col-4 d-flex align-items-center justify-content-end">
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={onDeselectMain}
                                    >
                                      Deseleccionar
                                    </button>
                                  </div>
                                </div>
                              </div>
                              : <></>
                          }
                        </div>

                        <hr />

                        <div className="d-flex align-items-start flex-column w-100">
                          <i className="mb-1">Trabajos complementarios</i>
                          {
                            complementaryResponses.length > 0
                              ? <div className="d-flex flex-column w-100 bg-white">
                                {
                                  complementaryResponses.map((response, index) =>
                                    <div className="row my-1 px-2" key={index}>
                                      <div className="col-1 d-flex align-items-center justify-content-center">{response.id}</div>
                                      <div className="col-5 d-flex align-items-center">{response.user.firstName} {response.user.lastName}</div>
                                      <div className="col-2 d-flex align-items-center">
                                        {String(new Date(mainResponse.createdAt).getDay()).padStart(2, '0') + "." +
                                          String(new Date(mainResponse.createdAt).getMonth()).padStart(2, '0') + "." +
                                          new Date(mainResponse.createdAt).getFullYear()}</div>
                                      <div className="col-4 d-flex align-items-center justify-content-end">
                                        <button
                                          className="btn btn-sm btn-primary"
                                          onClick={() => onDeselectComplementary(index)}
                                        >
                                          Deseleccionar
                                        </button>
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                              : <></>
                          }
                        </div>

                        <hr />

                        <button
                          className="btn btn-primary w-100"
                          onClick={onExport}
                        >
                          <i class="fas fa-file-export mr-1 text-white"></i> Exportar información
                        </button>

                      </div> : null}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </main>;

}

Admin.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  history: PropTypes.object
};

export default Admin;